.challengeCard {
  border: 1.14013px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: inset 0px -2.85032px 0px #ebebeb;
  border-radius: 4.56051px;
  width: 240px;
  height: 220px;
  padding: 15px;
  cursor: pointer;
  position: relative;
}

.challengeCard:hover {
  background: rgba(0, 94, 184, 0.1);
  box-shadow: inset 0px -2.84903px 0px #005eb8;
  border: 1.14013px solid #005eb8;
}
.newTag {
  position: absolute;
  right: -6%;
  top: -7%;
  font-size: 12px;
  line-height: 15px;
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  font-weight: 700;
}
.challengeCard > h6 {
  font-weight: 700;
  padding: 0;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 12px;
}
.challengeCard > p {
  font-size: 11px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  height: 68px;
  line-height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.dayLeft {
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.dayLeft > span :first-child {
  margin-right: 6px;
  font-size: 14px;
}
.challengeButton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  height: 30px;
  border: 1.14013px solid #27262b;
  background-color: transparent;
  border-radius: 22px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
}
.challengeButton > span:first-child {
  margin-right: 5px;
  font-size: 16px;
}
