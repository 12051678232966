 .riseUpMode {
     height: 100%;
     background-color: #fff;

     *:not(.katex *) {
         font-family: 'Open Sans', sans-serif !important;

     }

     .App:has(.HomeScreen) {
         overflow-x: hidden;
     }

     .reminderLabel {
         color: #263C66;
     }

     .HomeScreen *:not(.katex *) {
         font-family: 'Open Sans', sans-serif !important;

     }

     .HomeScreen .challengeSection {

         margin-top: 45px;

         a {
             margin-bottom: 5px;
         }

         * {
             color: #263C66;
         }

         .cNewTag {
             color: #fff;
         }

         h2 {
             color: #263C66;
             font-weight: 600;
         }
     }

     .riseUpLayout {
         .homeNav_ru {
             display: flex;
             justify-content: space-between;
             align-items: center;

             .addToCalendar {
                 width: 30px;
                 font-size: 32px;

                 svg {
                     cursor: pointer;
                 }
             }

             .memoTitle {
                 margin-top: 14px;
                 font-size: 18px;
                 font-weight: 600;
                 color: #263C66;
             }
         }

         .ActionScreen {
             margin-top: 24px;
             justify-content: flex-start;
         }

         .progressData {
             padding-bottom: 30px;
             box-sizing: border-box;
             width: 100%;
             max-width: 100%;
         }
     }

     @media screen and (min-width: 1400px) {
         .LearningSessionResult {
             padding: 3px;

             .heading {
                 margin: 0px 20%
             }
         }

     }

     .LearningSessionResult {
         padding-top: 20px;

         .heading {

             p,
             div {
                 color: #263C66;
             }
         }
     }

     .correctionSection {
         padding: 18px 0 30px;

         .correctionLabel {
             font-size: 18px;
             font-weight: 600;
             color: #263C66;
             text-align: left;
         }

         .correction {
             justify-content: flex-start;

             .correctionCard {
                 margin: 0px 16px 16px 0px;
             }
         }
     }

 }