.noChallengeCard {
  border: 1.14013px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: inset 0px -2.85032px 0px #ebebeb;
  border-radius: 4.56051px;
  width: 240px;
  height: 220px;
  padding: 10.26px;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.noChallengeCard > div {
  background-color: #ebebeb;
  border-radius: 20px;
}

/* loading animation  */
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.noChallengeCard > .linearBackground {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 3%, #dddddd 33%, #eeeeee 50%);
  overflow: hidden;
}
