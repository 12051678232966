.progressData {
    margin-top: 45px;

    .sectionTitle {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #263C66;
        margin-bottom: 24px;
        font-weight: 600;
        cursor: pointer;

        span {
            margin-bottom: -5px;
            font-size: 26px;
            margin-left: 6px;
        }
    }

    .formationWrapper.disabledCourse {
        background-color: #F8F8F9;
        border-color: #F8F8F9;

        .dropIcon {
            color: #898AA0 !important;
        }

        .skillName {
            color: #898AA0 !important;
        }

        .skillLabels {
            .firstLine {
                background-color: #F8F8F9 !important;
            }

            .firstLineLables {
                background-color: #F8F8F9 !important;
            }
        }

        .progressBar {
            background-color: #898AA0 !important;
        }
    }

    .formationWrapper {
        border: 1px solid #F3F3F7;
        border-radius: 8px;
        margin-bottom: 16px;

        .firstLine2 {
            padding: 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }

        .skillsSection {
            cursor: default;
            border-top: 1px solid #F3F3F7;
        }

        .formationProgress {
            width: 95%;
        }

        .dropIcon {
            color: #263C66;
            width: 20px;
        }

    }
}

.formationProgress {
    .col21 {
        margin-left: 10%;
    }
}

@media only screen and (max-width: 2000px) {
    .formationProgress {
        .col21 {
            margin-left: 10%;
        }
    }
}

@media only screen and (max-width: 1700px) {
    .formationProgress {
        .col21 {
            margin-left: 9%;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .formationProgress {
        .col21 {
            margin-left: 6.5%;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .formationProgress {
        .col21 {
            margin-left: 5%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .formationProgress {
        .col21 {
            margin-left: 4%;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .formationProgress {
        .col21 {
            margin-left: 2%;
        }
    }
}

@media only screen and (max-width: 800px) {
    .formationProgress {
        .col21 {
            margin-left: 4%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .formationWrapper {
        .formationProgress {
            width: 90%;

            .col21 {
                margin-left: 10%;
            }
        }

    }
}

@media only screen and (max-width: 400px) {
    .formationProgress {
        .col21 {
            margin-left: 10%;
        }
    }
}