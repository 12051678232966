.LearningSessionResult {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 0 0 0;

  @media screen and (min-width: 1400px) {
    padding: 30px 10% 0 10%;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: 0;

    div.close {
      cursor: pointer;
      margin: 15px;
      height: 40px;
    }

    .close {
      transition: 0.2s;
      color: #a1a1a1;

      &:hover {
        color: #686868;
      }
    }
  }

  .heading {
    padding: 30px;
    border-radius: 32px;
    background-color: #edf4fa;
  }

  section.container {
    display: block;
  }

  .badges {
    display: flex;
    justify-content: center;
  }

  .badge {
    margin: 0 20px;

    .wrapper {
      position: relative;
    }

    &.reviews .wrapper {
      background-color: rgb(235, 235, 235);

      .data {
        color: rgb(167, 167, 167);
      }

      .icon {
        top: 0;
        right: 0;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: -5px;
      right: -20px;

      // color: #EDF4FA;
      &.reviews {
        background-color: initial !important;

        svg {
          width: 24px;
          height: 24px;
          color: rgb(167, 167, 167) !important;
        }
      }
    }
  }

  .container {
    padding: 30px;
  }

  table#progress {
    width: 100%;

    .legend {
      height: 20px;

      td:nth-child(3) {
        display: flex;
        justify-content: space-between;
        color: rgb(167, 167, 167);
        font-size: 11px;
      }
    }

    thead th:not(:last-child),
    tbody td:not(:last-child) {
      padding: 0 25px 0 10px;
    }

    thead {
      tr {
        height: 60px;
        vertical-align: top;
      }

      th {
        font-size: 20px;
        text-align: left;
      }
    }

    tbody {
      tr {
        height: 60px;
      }

      td:nth-child(1),
      td:nth-child(2) {
        width: 25%;
        color: #686868;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        // Text wrap style
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      td:nth-child(3) {
        width: 100%;

        &:not(.no-border) {
          position: relative;
          border-left: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb;

          &::before {
            content: '';
            width: 1px;
            height: 100%;
            background-color: #ebebeb;
            position: absolute;
            top: 0;
            left: 33%;
          }

          &::after {
            content: '';
            width: 1px;
            height: 100%;
            background-color: #ebebeb;
            position: absolute;
            top: 0;
            right: 33%;
          }
        }
      }

      .row {
        .simpleProgressBarWrapper {
          width: 100%;
          display: block;

          .simpleProgressBarTrail {
            width: 100% !important;
          }
        }
      }
    }
  }

  .correctionLabel {
    margin-top: 0;
  }

  .correction {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;

    .correctionCard {
      width: 32%;
      margin: 0 0.5% 15px;
      padding: 15px;
      height: 150px;
      max-width: 350px;
      border-radius: 25px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        transition-duration: 200ms;
        transform: scale(1.01);
        box-shadow: 1px 5px 10px #dedede;
      }

      .row {
        margin: 0 0 10px 0;
        display: flex;
        justify-content: space-between;
      }

      .title {
        font-size: 14px;
        color: rgb(150, 150, 150);
        text-transform: uppercase;
        line-height: 20px;
        margin: 12px 0 2px;
        // Text wrap style
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
      }

      .content {
        font-size: 16px;
        padding: 5px 0;
        // margin: 10px 0 0 0;
        color: rgb(104, 104, 104);
        // Text wrap style
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .success {
      border: 1px solid #5dd9aa;
      box-shadow: 0 0 6px 0px rgba(93, 217, 170, 0.5);

      svg {
        color: rgb(50, 210, 150);
      }
    }

    .danger {
      border: 1px solid #ed7f7f;
      box-shadow: 0 0 6px 0px rgba(237, 127, 127, 0.5);

      svg {
        color: rgb(221, 46, 46);
      }
    }
  }

  .footer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: center;
    padding-bottom: 10px;

    button {
      margin: 0 10px;
    }
  }

  @media (max-width: 900px) {
    padding: 0;
  }

  @media (max-width: 700px) {
    .close svg {
      width: 20px;
    }

    table#progress {
      thead {
        th:nth-child(1) {
          display: none;
        }
      }

      tbody {
        td:nth-child(1) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 550px) {
    .badge {
      .data {
        font-size: 40px;
      }

      .label {
        max-width: 60px;
      }
    }

    table#progress {
      thead {
        th:nth-child(2) {
          display: none;
        }
      }

      tbody {
        td:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.Correction {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .header {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: 0;

    div.close {
      cursor: pointer;
      margin: 15px;
      height: 40px;
    }

    .close {
      transition: 0.2s;
      color: #a1a1a1;

      &:hover {
        color: #686868;
      }
    }
  }

  .Slideshow {
    height: 100%;

    // position: relative;
    .previous,
    .next {
      cursor: pointer;
      z-index: 1;
      top: 50%;
      position: absolute;
      // Animate fadeIn
      transition: 1s;
      animation: showMe 1s 0.2s forwards;
      opacity: 0;

      svg {
        color: #7f7f7f;
      }
    }

    .previous {
      left: 0;
    }

    .next {
      right: 0;
    }

    .container {
      overflow: hidden;
      padding: 0 20px;
    }
  }

  &.learning_sessions {

    // hides <Question /> input buttons
    .Question .Footer .ButtonWrapper {
      display: none;
    }
  }

  &.learning_actions {

    // hides <Question /> interactions
    .Question {

      .MicroAction,
      .Footer {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}