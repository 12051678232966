body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
* {
  font-family: 'Open Sans';
}

body #root {
  height: 100vh;
}
body #root .App {
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
body #root .downloadMode {
  background-color: #f5f5f5;
  transition-duration: 0s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Modal */
body #modal-root {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
}
body #modal-root.active {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}
body #modal-root.active + #root {
  filter: blur(4px);
}
body #modal-root.active img {
  width: 100%;
  border-radius: 20px;
  filter: drop-shadow(0px 3.22663px 18.5531px rgba(0, 0, 0, 0.25));
}
body #modal-root.active svg {
  cursor: pointer;
  top: -40px;
  right: -40px;
}

body > iframe {
  display: none;
}

@media (max-width: 600px) {
  body #root {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

.flex11100 {
  flex: 1 1 100%;
}

/* Hide scrollbar track */
::-webkit-scrollbar {
  width: 12px; /* Adjust width as needed */
  height: 12px; /* Adjust height as needed for horizontal scrollbars */
}

/* Hide the scrollbar track */
::-webkit-scrollbar-track {
  background: transparent; /* Hide the track by making it transparent */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded edges for the thumb */
  border: 4px solid transparent; /* Optional: Add space around the thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #888 transparent; /* Color of thumb and transparent track */
}
