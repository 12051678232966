.ActionTimeline {
    display: flex;

    .dividerContainer {
        z-index: -1;
        display: flex;
        position: absolute;
    }

    .dividerVertical {
        top: 100%;
        height: 100%;
        flex-direction: column;
    }

    .dividerHorizontal {
        width: 50%;
        align-items: center;
    }

}