.correctionCardRU {
    width: 316px;
    padding: 16px;
    border-radius: 8px;
    border: 2px solid #50C58F !important;
    background-color: #F4FBF8;
    margin-right: 16px;
    margin-bottom: 16px;
    box-shadow: none !important;
    box-sizing: border-box;
    height: 136px;
    cursor: pointer;

    svg {
        font-size: 24px;
    }

    .svgCircle {
        border: 2px solid;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: #50C58F;
        padding: 2px;

        svg {
            font-size: 14px;
        }

    }

    .section1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .courseName {
            padding: 4px 6px;
            border-radius: 6px;
            background-color: #F3F3F7;
            font-size: 12px;
            font-weight: 700;
            max-width: 230px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            color: #8290B4;
        }
    }



    .section2 {
        margin-top: 12px;

        .skillName {
            color: #263C66;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            width: 280px;
            white-space: nowrap;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
        }

        .correction_content {
            color: #8290B4;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
        }

    }

}

.correctionCardRU.danger {
    border: 2px solid #DD2E2E !important;
    background-color: #FCF0F0;

    .svgCircle {

        border-color: #DD2E2E;


    }
}