.progressRiseUpTsr {

    .progressData {
        margin-top: 0;

        .sectionTitle {
            pointer-events: none;
            font-weight: 600;
        }

        .formationWrapper {
            .firstLine2 {
                cursor: default
            }

            .formationProgress {
                width: 100%;

                .dateSection {
                    display: none;
                }

                .col2 {
                    justify-content: flex-end;
                    display: none;

                    .col21 {
                        display: none;
                    }
                }

            }
        }
    }


}

.LearningSessionResult section.container:has(.progressData) {
    margin-top: 18px;
    padding: 30px 0;
}