.onboardingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  height: 80vh;

  .title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 69px;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 40px;
    padding: 28px 100px 44px;
    @media (max-width: 600px) {
      height: fit-content;
      justify-content: center;
      align-items: center;
      padding: 28px 50px 44px;
    }
    .steps {
      margin: 0 auto 20px;
      max-width: 400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      & > span:nth-child(2) {
        padding-left: 1px;
      }
      & > span {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        box-sizing: border-box;
        line-height: 24px;
      }
      &::after {
        position: absolute;
        content: '';
        left: 24px;
        right: 24px;
        top: 50%;
        height: 1px;
        background-color: #dbdbdd;
      }
    }
    .infoArea {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      @media (max-width: 600px) {
        flex-direction: column-reverse;
      }
      .colLeft {
        margin-right: 60px;
        @media (max-width: 600px) {
          margin-right: 0;
          width: 100%;
        }
        .textMain {
          font-size: 32px;
          margin-bottom: 22px;
          font-weight: 900;
          @media (max-width: 1000px) {
            font-size: 24px;
            margin-bottom: 15px;
          }
        }
        .otherText {
          font-size: 20px;
          font-weight: 400;
          color: #686868;
          max-width: 300px;
          line-height: 23px;
          @media (max-width: 1000px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        button {
          font-weight: 700;
          margin-top: 40px;
          display: flex;
          padding: 18px 30px;
          flex-direction: column;
          align-items: center;
          border-radius: 40px;
          font-size: 14px;
          color: white;
          border: none;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          @media (max-width: 1000px) {
            padding: 15px 20px;
            border-radius: 40px;
            font-size: 12px;
          }
        }
      }
      .colRight {
        width: 200px;
        @media (max-width: 1000px) {
          width: 150px;
        }
        @media (max-width: 600px) {
          width: 50%;
          margin: 0 auto;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          aspect-ratio: 1;
        }
      }
    }
  }
  .bottomTextWrapper {
    width: 100%;
    margin-top: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .bottomText {
      color: #686868;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      border-bottom: 1px solid #686868;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      svg {
        margin-left: 3px;
        margin-bottom: -1px;
      }
    }
  }
  .closeIcon {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 25px;
    color: #a7a7a7;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
      transition-delay: 10ms;
    }
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  .mailModal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(99, 99, 99, 0.57);
    .container {
      position: relative;
      border-radius: 10px;
      padding: 68px 104px;
      width: 310px;
      min-height: 280px;
      flex-direction: column;
      background-color: #fff;
      .title {
        font-size: 36px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        color: #27262b;
      }
      .subText {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        color: #27262b;
        margin: 15px 0px 22px;
      }
      .inputWrapper {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        background-color: #f3f3f3;
        color: #686868;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        padding: 8px 17px;
        input {
          margin-left: 20px;
          width: 80%;
          outline: none;
          color: #686868;
          border: none;
          background-color: inherit;
        }
      }
      .alertMessage {
        color: rgb(221, 46, 46);
        font-size: 14px;
        margin-top: 5px;
        min-height: 18px;
      }
      .alertBorder {
        border-color: rgb(221, 46, 46);
      }
      button {
        max-height: 52px;
        padding: 18px 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        border-radius: 40px;
        width: max-content;
        margin: 0 auto;
        margin-top: 32px;
        img {
          width: 30px !important;
        }
      }
      .closeIcon {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 25px;
        color: #a7a7a7;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
          transition-delay: 10ms;
        }
      }
    }
    .container2 {
      padding: 68px 24px;
      width: 470px;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        margin-bottom: 50px;
      }
    }
  }
}
