.SettingsScreen {
  height: calc(100vh - 120px);
  padding: 10px 0;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  .close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 25px;
  }

  .settingsPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    padding-top: 50px;

    .settingsLine1 {
      margin: 25px 0;
    }

    .settingsLine2.logoutLine {
      margin-top: 40px;
      flex: 1;
      align-items: flex-end;
    }
  }
}

// hide log out button for scorm users
.SettingsScreen.scormUser {
  .logoutLine {
    display: none;
  }

}