.App {
  padding: 10px 40px;
}

.App:has(.Question) {
  padding: 0px;
}

a {
  text-decoration: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 640px) {
  .App {
    padding: 10px;
  }
}

.App,
.LearningSessionResult {
  transition: 0s;
  animation: showMe 1s 0.2s forwards;
  opacity: 0;
}

.App:has(.LearningSessionResult),
.App:has(.Correction) {
  transition: 0s;
}
.App:has(.Slideshow) {
  padding: 0 !important;
  overflow: auto !important;
}

.Correction .Question .Content {
  padding: 20px 40px !important;
}

@keyframes showMe {
  to {
    opacity: 1;
  }
}
