.LearningSession {
  section.container {
    display: block;
  }

  section.container>div[class*='domWidth100'] {
    margin-top: 260px;
  }

  .progress {
    display: flex;
    justify-content: center;
    padding-left: 40px;
    margin-bottom: 40px;

    .simpleProgressBarTrail {
      width: 400px !important;
    }
  }

  &[data-index='0'] {
    .Question {
      animation: fadeIn 1s;
    }
  }

  .Holes,
  .Matching,
  .MicroAction,
  .OpenText,
  .Qcm {
    margin-bottom: 30px;
  }


}