/**
 * HomeScreen
 */


.HomeScreen {
  .HomeNavLink {
    margin-right: 60px;
  }

  .homeScreenHeader {
    a {
      position: relative;
    }

    .notifSign {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      border: 2px solid white;
      background-color: #d25252;
      position: absolute;
      top: -4px;
      right: -2px;
    }
  }
}

/**
 * OnboardingScreen
 */

.OnboardingScreen {
  a[class*='domButton'] {
    width: 200px;
  }
}

/**
 * DownloadMobileScreen
 */

.DownloadMobileScreen {
  .StoreBadge {
    height: 50px;
    width: 160px;
  }

  .QRCode {
    height: 120px;
    width: 120px;
  }

  .MobileImg {
    height: 441px;
    width: 234px;
    border-radius: 36px;
    -webkit-box-shadow: 6px 6px 20px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 6px 6px 20px 3px rgba(0, 0, 0, 0.3);
  }

  .notification {
    position: absolute;
    z-index: 2;
    top: 30%;
    left: -15%;
    background-color: white;
    border-style: solid;
    border-color: transparent;
    border-radius: 15px;
    box-shadow: -2px 3px 3px #a7a7a7;
    width: 150px;
    font-size: 10px;

    -webkit-animation: fadeIn 2s ease-out 0s;
    animation: fadeIn 2s ease-out 0s;
    -webkit-animation-direction: alternate;

    animation-direction: alternate img {
      width: 16px;
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      padding: 2px;
    }
  }

}

/**
 * ActionScreen
 */

.ActionScreen {
  .marginNegative40 {
    margin-left: -40px;
  }

  .marginNegative80 {
    margin-left: -80px;
  }

  .zIndex0 {
    z-index: 0;
  }

  .zIndex1 {
    z-index: 1;
  }

  .left0 {
    left: 0%;
  }

  .left50 {
    left: 50%;
  }
}

/**
 * LearningSessionScreen
 */
.CorrectionScreen,
.LearningSessionScreen,
.LearningSessionScreen>div {
  height: 100%;

  .iconWrapper {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: -14px;
    right: -16px;
    background-color: #a7a7a7;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconAndType {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
}

/**
 * LearningSessionResultScreen
 */
.LearningSessionResultScreen {
  .Header {
    border-radius: 32px;
    background-color: #edf4fa;

    .badgeWrapper {
      position: relative;
    }

    .iconWrapper {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: -5px;
      right: -20px;
      color: '#EDF4FA';
    }
  }

  .Main {
    .height60 {
      height: 60px;
    }

    .RightCol {
      div.height60:first-of-type {
        height: 60px;
      }

      div.height60.x2 {
        height: 120px;
      }

      .BarChart {
        $progress-color: #ffd166;
        $neutral-color: #ebebeb;

        .Labels {
          height: 60px;

          >div {
            height: 60px;
            width: 80px;
          }
        }

        .Chart {
          border-left: 1px solid $neutral-color;
          border-right: 1px solid $neutral-color;

          // Last bar is shorter for borders display.
          >div:last-of-type {
            height: 12px;
          }

          hr {
            position: absolute;
            height: 100%;
            margin: 0;
            background-color: $neutral-color;
            border: none;
            width: 1px;
          }

          .Bar {
            height: 60px;

            .star {
              position: absolute;
              right: -70px;
              top: -9px;

              svg {
                font-size: 32px;
                color: $progress-color;
              }
            }
          }
        }
      }
    }
  }

  .Footer {
    overflow: hidden;
  }

  .CorrectionScreen {
    .slider {
      position: absolute;
      top: 50%;
      font-size: 48px;
    }

    .card {
      width: 300px;
      border-width: 3px;
      border-style: solid;
      border-radius: 25px;
      height: 120px;
    }

    .card .label {
      align-self: flex-start;
      margin: 0;
    }
  }

  .challengeDetails {
    .qti_content {
      margin-top: 50px;
    }

    .iconWrapper {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: -10px;
      right: -10px;
      color: '#EDF4FA';
    }

    iframe {
      width: 90%;
      margin-top: 30px;
      height: 33vh;
    }

    >div>main {
      padding-bottom: 30px !important;
    }

    .QCM>div>div:first-child {
      margin-bottom: 50px;
    }
  }
}

/*
 * ProgressScreen
 */
.ProgressScreen {
  iframe {
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    border-color: #ebebeb;
    display: flex;
  }
}

/*
 * Errors
 */
// shared
.errorContainer {
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.errorBackground {
  position: fixed;
  width: 2500px;
  bottom: -60%;
  left: calc(50% - 1250px);
  z-index: -1;
  font-size: 1250px;
  font-weight: bold;
  color: rgba(218, 218, 218, 0.22);
}

.errorContent {
  margin: 0 auto;
  margin-top: auto;
  padding-bottom: 50px;
}

.errorWidthButton {
  width: 170px !important;
}

.LearningSessionScreen .errorContent {
  transform: scale(0.7);
  padding-bottom: 0px !important;
}

.errorLabel {
  font-size: 20px;
  font-weight: bold;
}

// not found
.notFoundContent {
  margin-bottom: -80px;
}

.notFoundContent {
  button {
    z-index: 2;
  }
}

.notFoundContainer {
  height: 670px;
  width: 800px;
  overflow: hidden;
  position: relative;
}

.notFoundTitle {
  font-size: 48px;
  font-weight: bold;
  position: absolute;
  width: 200px;
  transform: rotate(17.5deg);
  top: 211px;
  left: 192px;
}

// access denied
.accessDeniedContainer {
  height: 625px;
  width: 1000px;
  overflow: hidden;
}

.accessDeniedTitle {
  margin-bottom: -50px;
  font-size: 48px;
  font-weight: bold;
}

// internal error
.internalErrorContainer {
  height: 750px;
  width: 800px;
  overflow: hidden;
  margin-bottom: -20px;
}

.internalErrorTitle {
  margin-bottom: -175px;
  font-size: 48px;
  font-weight: bold;
}

// Error
.crashErrorBackground {
  position: fixed;
  width: 2500px;
  bottom: -35%;
  left: calc(50% - 1250px);
  z-index: -1;
  font-size: 800px;
  font-weight: bold;
  color: rgba(218, 218, 218, 0.22);
}

.crashErrorContainer {
  height: 700px;
  width: 700px;
  overflow: hidden;
  margin-bottom: -20px;
}

.crashErrorTitle {
  margin-bottom: -210px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  padding-right: 170px;
}

//fallback
.width190 {
  width: 190px !important;
}

.width225 {
  width: 250px !important;
}

.logoDomoscio {
  width: 800px;
}

.fallbackContainer {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 400px);
}

// Welcome screen
.WelcomeScreen {
  margin: 0 auto;
  width: max-content;
  border-radius: 10px;
  padding: 20px;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    color: #a7a7a7 !important;
  }

  >* {
    width: 50vw;
    // text-align: left;
  }
}

.ActionScreen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  justify-content: space-between;
}

.ThemeOnStartScreen {
  display: flex;
  align-items: center;
}

.ThemeOnStartScreen,
.ThemeOnStartScreen>div {
  height: 100%;
}

@media (max-width: 900px) {
  .LearningSessionResultScreen {
    padding-left: 20px !important;
    padding-right: 20px !important;

    header {
      .line2 {
        &>div {
          width: 100%;
          justify-content: center;

          &>div {
            margin: 0;
            width: 33%;
          }
        }

        .resultBadge {
          font-size: 40px;

          span {
            font-size: 40px;
          }
        }

        .badgeText {
          text-align: center;
        }

        .iconWrapper {
          top: -5px;
          right: -10px;
        }
      }
    }

    .barChartsContainer {
      margin-top: 20px;
      padding: 10px;
      padding: 0;

      .LeftCol {
        &>div:first-child {
          font-size: 16px;
        }
      }

      .RightCol {
        .BarChart {
          .Chart {
            .Bar {
              .star {
                right: -50px;
              }
            }
          }
        }

        &>div {
          font-size: 16px;
        }
      }
    }
  }

  .settingsScreen {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 600px) {
  .Holes {
    .holePropList {
      flex-wrap: wrap;
    }
  }

  .Matching {
    .dropItems {
      min-width: 400px;
    }
  }

  .QCM,
  .Challenge {
    .qcmChoices {
      flex-direction: column;

      &>div {
        width: 100%;
        flex: 1 1 100%;
      }
    }
  }

  .Feedback {
    .feedbackText {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .LearningSessionScreen,
  .LearningSessionResultScreen {
    min-height: calc(100vh - 30px);

    .Feedback {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .lsProgress {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 30px;

      .closeIconWrapper {
        margin-right: 10px;

        .closeIcon {
          font-size: 30px;
        }
      }
    }

    .lsMain {
      padding-left: 15px;
      padding-right: 15px;
      max-height: max-content !important;
      min-height: -webkit-fill-available;
      height: max-content !important;
      box-sizing: border-box;
    }
  }

  .LearningSessionScreen {
    position: relative;
    padding-top: 100px;
    padding-bottom: 50px;

    &>div {
      height: max-content;
    }

    .Feedback {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .emptyFeedback {
      background-color: white;
      height: 80px;
      width: 100%;
    }

    .lsProgress {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: white;
      height: 40px;
      margin-top: 0;
      padding-top: 30px;
      z-index: 100;
    }

    &>div {
      footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 20px;
      }
    }
  }

  .ActionScreen {
    .marginNegative40 {
      margin-left: 0px;
    }
  }

  .ActionLoading {
    margin-left: 40px;
  }

  .settingsScreen {
    .Form {
      flex-direction: column;
    }

    .colRightContent {
      margin-left: 5px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .LearningSessionResultScreen {
    overflow: hidden;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: 10px;

    .closeIcon {
      transform: scale(0.8);
      margin-top: 5px;
      position: fixed;
      text-align: end;
      margin-right: 10px;
      z-index: 1000;

      svg {
        background-color: white;
        border-radius: 50%;
      }
    }

    header {
      padding-top: 20px;
      padding-bottom: 0px;

      .line1 {
        padding: 0 10px;
      }

      .line2 {
        &>div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;

          &>div {
            transform: scale(0.9);
            width: 80px;
            display: flex;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }

            .badgeText {
              max-width: 120px;
            }
          }
        }

        .resultBadge {
          font-size: 40px;

          span {
            font-size: 40px;
          }
        }

        .badgeText {
          text-align: center;
        }
      }
    }

    .barChartsContainer {
      .LeftCol {
        width: 25%;

        &>div:first-child {
          font-size: 14px;
          min-height: 60px;
        }

        &>div {
          &>div:first-child {
            display: none;
          }

          &>div:last-child {
            width: 100%;
          }
        }
      }

      .RightCol {
        width: 60%;

        .rightColTitle {
          min-height: 60px;
          font-size: 14px;
          text-align: center;
        }

        .BarChart {
          .Labels {
            &>div {
              width: 90px !important;
              transform: rotate(-45deg);
              transform-origin: bottom;
              font-size: 11px;
              line-height: 15px;
              margin-top: -10px;
              height: 40px;
            }

            &>div:last-child {
              left: 90% !important;
            }
          }

          .Chart {
            .Bar {
              .star {
                right: -50px;
              }
            }
          }
        }
      }
    }

    .Footer {
      padding-top: 10px;
      padding-bottom: 20px;
      justify-content: space-around;
      position: fixed;

      &>* {
        font-size: 12px;
        transform: scale(0.9);
        margin-left: 0;
        margin-bottom: 0;
        margin-right: 0;
        margin-top: 0;
      }
    }

    .CorrectionScreen {
      .card {
        width: 250px;
        height: 100px;

        .questionText {
          font-size: 16px;
          text-overflow: ellipsis;
          word-wrap: break-word;
          height: 40px;
          line-height: 17px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .slider {
        background-color: #edf4fa;
        border: 1px solid gray;
        height: 30px;
        width: 30px;
        font-size: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .sliderLeft {
        left: 15px !important;
      }

      .sliderRight {
        right: 15px !important;
      }
    }
  }

  .ActionScreen {
    padding: 0 20px;
  }

  .homeSceenHeader {
    nav {
      flex-direction: column-reverse;

      &>div:first-child {
        width: 100%;
        justify-content: space-around;
      }

      &>div:last-child {
        margin-top: -20px;
        margin-bottom: 20px;
      }

      .HomeNavLink {
        margin-right: 20px;
      }
    }
  }
}

.alertStatusColor {
  color: rgb(192, 4, 4);
}