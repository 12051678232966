/* Container styling */
.AddToCalendarContainer {
    position: absolute;
    top: -5px;
    right: 0;

    .tooltip-box {
        margin-bottom: 0;
    }

    .calendar-dropdown {
        position: relative;
        display: inline-block;

        /* Calendar button styling */
        .calendar-button {
            border: none;
            border-radius: 50%;
            min-width: 30px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: white;
            font-size: 16px;
            background-color: rgb(235, 235, 235, 0.1);
        }

        .calendar-button:hover {
            opacity: 0.9;
        }

        /* Dropdown menu styling */
        .dropdown-menu {
            position: absolute;
            top: 30px;
            left: 0;
            background-color: white;
            border-radius: 20px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 100000;
            opacity: 0;
            transform: translateY(-10px);
            /* Slightly slide up */
            pointer-events: none;
            /* Prevent clicks when not visible */
            transition: opacity 0.3s ease, transform 0.3s ease;
            overflow: hidden;

        }

        .dropdown-menu.show {
            opacity: 1;
            transform: translateY(0);
            /* Reset the slide */
            pointer-events: auto;
            /* Enable clicks */
        }

        /* Dropdown item styling */
        .dropdown-item {
            padding: 20px;
            background-color: white;
            border: none;
            text-align: left;
            cursor: pointer;
            color: #263C66;
            font-size: 14px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            width: 200px;

            svg {
                margin-right: 10px;
                height: 20px;
                width: 20px;
            }
        }

        .dropdown-item:hover {
            background-color: rgb(235, 235, 235);
        }
    }

    .dropOpen {
        .tooltip-box {
            display: none;
        }
    }


}