/**
 * Scrolling
 */

.domScroll {
  overflow-y: auto !important;
}

.domScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background-color: transparent;
}

.domScroll::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.domScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a0a0a0;
}

.zIndex0 {
  z-index: 0;
}
.zIndex1 {
  z-index: 1;
}

.marginTop20 {
  margin-top: 20px !important;
}

.submitButton {
  max-width: 170px !important;
}
