
.challengeList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.challenges a {
  color: inherit;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-shrink: 0;
}
.challengeList > a:last-child {
  margin-right: 0;
}

.challenges  h2 {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0px;
  line-height: 43px;
}

.tourHomeStep3 {
  width: fit-content;
  padding: 5px;
}
