@import 'global';

/**
 * Form
 */
.Form {
  input {
    box-sizing: border-box;
    overflow: visible;
    max-width: 100%;
    width: 100%;
    border-style: none;
    border-width: 0;
    padding: 0 40px;
    color: #4a4a4a;
    border-radius: 4px;
    font: inherit;
    background-color: rgba(0, 0, 0, 0.03);
    height: 50px;
    vertical-align: middle;
    display: inline-block;
    outline: none;
  }

  .icon {
    position: absolute;
    left: 0;
    margin-top: 13px;
    margin-left: 10px;
    color: #a7a7a7;
  }

  .password-icon {
    position: absolute;
    right: 0;
    margin-top: 13px;
    margin-right: 10px;
    color: #a7a7a7;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .error {
    border-style: solid;
    border-width: 1px;
  }
}

.Input {
  input {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    font: inherit;
    overflow: visible;
    position: relative;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  input::-moz-placeholder {
    opacity: 1;
  }

  input {
    -webkit-appearance: none;
  }

  input {
    max-width: 100%;
    width: 100%;
    border: 0 none !important;
    padding: 0 10px !important;
    color: #4a4a4a;
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, 0.03);
  }

  input {
    height: 40px;
    vertical-align: middle;
    display: inline-block;
  }

  input:not(input) {
    line-height: 38px;
  }

  input {
    outline: none;
  }

  input:disabled {
    background-color: rgba(0, 0, 0, 0.15);
    color: #999;
    border-color: #e5e5e5;
  }

  input::-ms-input-placeholder {
    color: #999 !important;
  }

  input::placeholder {
    color: #999;
  }
}

.lengthCounter {
  position: absolute;
  bottom: 0px;
  right: 15px;
}

/**
 * Dom Button animation
 */

[class*='domButton'] {
  box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.15);
}

[class*='domButton'][class*='domBorderPrimary'] {
  box-shadow: none !important;
  border-bottom-width: 4px;
}

[class*='domButton'][class*='domButtonCircle'] {
  width: 80px !important;
  box-sizing: border-box;
  box-shadow: none;
}

[class*='domButton'][class*='domButtonCircleMedium'] {
  width: 70px !important;
  border: 0;
}

[class*='domButton'][class*='domButtonCircleBorder'] {
  height: 80px !important;
  width: 80px !important;
}

[class*='domButtonCircle'][class*='domBgPrimary'] {
  box-shadow: inset 0px -7px 0px rgba(0, 0, 0, 0.15);
}

[class*='domButtonSecondary'] {
  box-shadow: inset 0px -2px 0px #005eb8;
}

[class*='domButton-']:not(:disabled) {
  position: relative;
  cursor: pointer;
}

[class*='domButton']:hover {
  opacity: 0.9;
}

[class*='domButton']:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

[class*='domButton']:disabled {
  opacity: 0.6;
}

@keyframes rippleDomButton {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

[class*='domButton']:focus:not(:active)::after {
  animation: rippleDomButton 1s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/**
 * LearningSession
 */

.bgTransparent {
  background-color: transparent !important;
}

/**
 * Checkboxes
 */

div[class^='domCheckboxProp'],
.focused {
  transition: all 0.3s ease-out;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.checkboxLabel p {
  width: 100%;
  margin: 20px 0;
}

.Checkbox-single,
.Checkbox-multiple {
  background-color: #fff;
}

.Checkbox-single {
  border-radius: 50%;
}

.Checkbox-multiple {
  border-radius: 4px;
}

/**
 * Holes
 */

.Holes div[class*='domSelectHole'],
.Holes span[class*='domToggle'] {
  cursor: pointer;
}

.Holes div[class*='domInputContainer'] svg {
  margin: 0 10px;
}

.Holes input[class*='domInput']:focus {
  outline: none;
}

.Holes:not(.correction) .focused[class*='domBorderGreyDk100'] {
  border-color: #fff !important;
}

.Holes:not(.correction) .focused {
  box-shadow: 0px 0px 0px 3px #005eb8;
}

.showInAnimate {
  border-radius: 60px;
  padding: 5px;
  -webkit-animation: showIn 1s ease-out 0s;
  -webkit-animation-direction: alternate;
}

@keyframes showIn {
  0% {
    background: 'transparent';
    box-shadow: 0 0 5px -3px rgba(26, 115, 232, 0);
  }

  50% {
    background: rgba(26, 115, 232, 0.1);
    box-shadow: 1px 10px 15px 0 rgba(26, 115, 232, 0.1);
  }

  100% {
    background: 'transparent';
    box-shadow: 0 0 5px -3px rgba(26, 115, 232, 0);
  }
}

@-webkit-keyframes showIn {
  0% {
    background: 'transparent';
    box-shadow: 0 0 5px -3px rgba(26, 115, 232, 0);
  }

  50% {
    background: rgba(26, 115, 232, 0.1);
    box-shadow: 1px 10px 15px 0 rgba(26, 115, 232, 0.1);
  }

  100% {
    background: 'transparent';
    box-shadow: 0 0 5px -3px rgba(26, 115, 232, 0);
  }
}

/**
 * Matching
 */

.Matching div[class*='domSortableFilled'] {
  background: #f7f7f7;
  box-shadow: inset 0px -5px 0px #ebebeb;
  // transition: .2s;
}

.Matching div[class*='domSortableFilled']:hover {
  transform: rotate(-1deg) translate(1px, -2px);
}

.Matching {
  .tooltipDndTextWrapper {
    position: relative;
  }

  .tooltipDndTextWrapper:hover .tooltipDndText {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
  }

  .dndTextBox {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .tooltipDndText {
    position: absolute;
    top: 114px;
    left: 0;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    background-color: rgb(235, 235, 235);
    padding: 5px 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    border-color: rgb(235, 235, 235);
    z-index: 2;
  }

  .tooltipDndText::after {
    content: '';
    top: -12px;
    right: 25px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid rgb(235, 235, 235);
  }

  div[class*='domFixed'],
  div[class*='domSortable'] {
    overflow: hidden;

    p {
      margin: 5px 0;
    }
  }
}

/**
 * Open
 */

.Open textarea[class*='domTextArea']:focus {
  outline: none;
}

.Open {
  .correction {
    min-width: 300px;
    max-width: 650px;
    min-height: 50px;
    width: 100%;
    border-style: solid;
    border-width: 2px;
    border-radius: 14px;

    div {
      font-size: 16px;
      border-width: 0px;
      margin: 15px;
    }
  }

  .rightAnswer {
    position: absolute;
    top: -25px;
    left: 0px;
  }

  textarea {
    resize: none;
  }
}

/**
* Images
*/
.fadeInImage {
  img {
    -webkit-animation: fadeIn 0.5s ease-out 0s;
    -webkit-animation-direction: alternate;
  }
}

/**
* Dom Select
*/

select:focus {
  outline: none;
}

/**
* Dom Cursor
*/

.domPointer {
  cursor: pointer;
}

/**
* Position Rjs
*/

.domPositionBottomCenter {
  bottom: 10px;
  left: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, 0);
}

.errorMessageForm {
  color: red;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 500;
  transform: translate(-50%, 0);
}

/**
* Border Radius for web percentage % - perfect circle
*/
.domRadius50 {
  border-radius: 50%;
}

/**
* Tour Tooltip Template
*/
.tourContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  font-size: 16px;
  max-width: 100%;
  padding: 15px;
  position: relative;
  width: 200px;
}

/**
* Action Placeholder
*/

/* loading animation  */
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.linearBackground {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 3%, #dddddd 33%, #eeeeee 50%);
  overflow: hidden;
}

.ActionLoading>div {
  background-color: #ebebeb;
}

.ActionLoading>div:nth-child(1) {
  border-radius: 50%;
}

.ActionLoading>div:nth-child(2) {
  border-radius: 20px;
}

/**
  * Learning Session Layout
*/
.LearningSessionLayout {

  /**
  * Icon for qti in Learning Session Layout
  */
  .iconLabelQti {
    border-radius: 50%;
    height: 1.7rem;
    width: 1.7rem;
  }
}

.ChallengeFeedback {
  min-height: calc(100vh - 20px);
  padding-bottom: 0;

  main>div:last-child {
    margin-top: 50px;
  }

  main>div:first-child {
    margin-bottom: 50px;
  }

  main>div:first-child>div:first-child {
    margin-bottom: 30px;
  }

  .primaryOpacity {
    background-color: rgba(0, 94, 184, 0.1) !important;
  }
}